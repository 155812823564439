import { queryOptions, useQuery } from '@tanstack/vue-query'
import type { IManagerFormValue } from '@manager/types'

export type ILeaseCardStorageParams = {
  divisionId: string
  leaseId: string
  fieldGroupId: number
}

export const leaseCardStorageOptions = ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) =>
  queryOptions({
    queryKey: [
      'companies',
      divisionId,
      'leases',
      leaseId,
      'storage',
      'node',
      fieldGroupId,
    ],
    queryFn: () =>
      fetchAPI<IManagerFormValue>(
        `/companies/${divisionId}/leases/${leaseId}/storage/node/${fieldGroupId}`,
        {
          query: {
            includeEmptyValues: false,
          },
        },
      ),
  })

export const useApiLeaseCardStorage = ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) => {
  return useQuery(
    leaseCardStorageOptions({ divisionId, leaseId, fieldGroupId }),
  )
}
